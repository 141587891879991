import { defineStore } from 'pinia'

export interface Route {
  id?: string | string[]
  name?: string
  path: string
  description?: string
}

export const useUsabilityStore = defineStore({
  id: 'usability',
  state: () => ({
    recentlyViewed: [] as Route[],
  }),
  actions: {
    updateRecent(route: Route) {
      const idx = this.recentlyViewed.findIndex(
        (r: Route) => r.path === route.path,
      )
      if (idx !== -1) {
        const removedItem = this.recentlyViewed.splice(idx, 1)[0]
        if (removedItem.description) {
          return this.recentlyViewed.unshift(removedItem)
        }
        this.recentlyViewed.unshift(route)
      } else {
        this.recentlyViewed.unshift(route)
      }
    },
  },
  persist: {
    storage: sessionStorage,
    paths: ['recentlyViewed'],
  },
})
